import { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import backgroundRegistry from "../../assets/backgroundRegistry.png";
import imageRegistry from "../../assets/imageRegistry.svg";
import companyLogo from "../../assets/svg/omniflou-large.svg";

import RegistryForm from "../../components/registryForm";

function DesktopView({
  t,
  setLang,
  lang,
  companyName,
  setCompanyName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  codePhone,
  setCodePhone,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  onRegistry,
  isLoading,
}) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    setHeight(window.innerHeight);
    console.log(height);
  }, [window.innerHeight]);

  return (
    <Box style={{ width: "100%", display: "flex" }}>
      <Box
        style={{
          width: "50%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: window.innerHeight > 800 && "center",
          flexDirection: "column",
          padding: 16,
          paddingTop: window.innerHeight <= 800 ? 64 : 24,
          paddingBottom: window.innerHeight <= 800 && 64,
          overflow: "auto",
        }}
      >
        <RegistryForm
          t={t}
          lang={lang}
          setLang={setLang}
          companyName={companyName}
          setCompanyName={setCompanyName}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          codePhone={codePhone}
          setCodePhone={setCodePhone}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          onRegistry={onRegistry}
          isLoading={isLoading}
        />
      </Box>
      <Box
        style={{
          height: "100vh",
          width: "50%",
          // backgroundSize: "cover",
          // backgroundImage: `url(${backgroundRegistry})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
        }}
      >
        <img src={imageRegistry} alt="" style={{ width: "75%" }} />
      </Box>
    </Box>
  );
}

export default DesktopView;
