import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";

import mobileBackground from "../../assets/mobile_background.png";
import mobileImageRegistry from "../../assets/mobile_image_registry.png";
import mobileLogo from "../../assets/logo_videolink_mobile.svg";
import companyLogoWhite from "../../assets/svg/omniflou-white.svg";
import RegistryForm from "../../components/registryForm";

function MobileView({
  t,
  lang,
  setLang,
  companyName,
  setCompanyName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  codePhone,
  setCodePhone,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  onRegistry,
  isLoading,
}) {
  const [isOpenDialog] = useState(true);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log(width);
  return (
    <Box style={{ height: "100vh" }}>
      <Box
        style={{
          height: isOpenDialog ? (height >= 1180 ? "14vh" : "25vh") : "45vh",
          width: "100%",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: height < 1024 && "center",
          flexDirection: "column",
          padding: 24,
        }}
      >
        {!isOpenDialog && (
          <img
            alt=""
            style={{ width: "225px", marginBottom: 24 }}
            src={mobileImageRegistry}
          />
        )}

        <img src={companyLogoWhite} style={{ maxWidth: "182px" }} alt="" />
      </Box>
      <Dialog
        fullScreen
        open={true}
        // TransitionComponent={Transition}
        PaperProps={{
          style: {
            borderTopLeftRadius: 10,
            height: isOpenDialog
              ? height < 1180 && width < 768
                ? "80%"
                : width > height
                ? "80%"
                : "90%"
              : "60%",
            position: "fixed",
            bottom: 0,
            width: "95%",
            borderTopRightRadius: 10,
          },
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          position: "relative",
        })}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // margin: "auto",
            padding: 16,
            width: "100%",
          }}
        >
          <RegistryForm
            t={t}
            lang={lang}
            setLang={setLang}
            companyName={companyName}
            setCompanyName={setCompanyName}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            codePhone={codePhone}
            setCodePhone={setCodePhone}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            isMobile={true}
            onRegistry={onRegistry}
            isLoading={isLoading}
            height={height}
            width={width}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

export default MobileView;
