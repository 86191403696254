import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Logo_videolink from "../assets/Logo_videolink.png";
import compayIcon from "../assets/empresa.svg";
import userIcon from "../assets/user.svg";
import emailIcon from "../assets/email.svg";
import passwordIcon from "../assets/contraseña.svg";
import CustomRadio from "./customRadio";
import companyLogo from "../assets/svg/omniflou-large.svg";

import { consts } from "../consts";

import { REACT_URL, environment } from "../config";

function RegistryForm({
  t,
  setLang,
  lang,
  companyName,
  setCompanyName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  codePhone,
  setCodePhone,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  onRegistry,
  isMobile,
  isLoading,
  height,
  width,
}) {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [validatePasswordForOneLetter, setValidatePasswordForOneLetter] =
    useState(false);
  const [
    validatePasswordForOneLetterUppercase,
    setValidatePasswordForOneLetterUppercase,
  ] = useState(false);
  const [validatePasswordForOneNumber, setValidatePasswordForOneNumber] =
    useState(false);
  const [validatePasswordLength, setValidatePasswordLength] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(false);

  const regexForName = /^[a-zA-Z\s\W]*$/;
  const regexForEmail =
    /^[A-Za-z0-9_]+(\.[_A-Za-z0-9_]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/;

  const regexForPhone = /^[0-9]*$/;
  const regexForOneLetter = /[a-zA-Z]/;
  const regexForOneUppecaseLetter = /[A-Z]/;
  const regexForOneNumber = /[0-9]/;
  const phoneCodes = [
    {
      id: 0,
      code: "+58",
    },
    {
      id: 1,
      code: "+57",
    },
    {
      id: 2,
      code: "+56",
    },
    {
      id: 3,
      code: "+1",
    },
    {
      id: 4,
      code: "+54",
    },
  ];

  useEffect(() => {
    if (password.length >= 8) {
      setValidatePasswordLength(true);
    } else {
      setValidatePasswordLength(false);
    }
    if (regexForOneLetter.test(password)) setValidatePasswordForOneLetter(true);
    else setValidatePasswordForOneLetter(false);
    if (regexForOneUppecaseLetter.test(password))
      setValidatePasswordForOneLetterUppercase(true);
    else setValidatePasswordForOneLetterUppercase(false);
    if (regexForOneNumber.test(password)) setValidatePasswordForOneNumber(true);
    else setValidatePasswordForOneNumber(false);
  }, [password]);

  useEffect(() => {
    if (!regexForEmail.test(email) && email !== "") setIsValidateEmail(true);
    else setIsValidateEmail(false);
  }, [email]);
  return (
    <Box
      style={
        {
          // display: isMobile && "flex",
          // flexDirection: isMobile && "column",
          // alignItems: isMobile && "center",
        }
      }
    >
      {!isMobile && (
        <Box>
          <Box style={{ marginBottom: !isMobile && 14, textAlign: "center" }}>
            <img src={companyLogo} alt="logo" style={{ width: 363 }} />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: !isMobile ? 14 : 14,
              width: "80%",
              borderRadius: 20,
              backgroundColor: "#D6D8E8",
              padding: 2,
              maxWidth: 500,
              margin: "auto",
            }}
          >
            <Box style={{ width: "50%" }}>
              <Button
                disabled={isLoading}
                onClick={() => setLang("es")}
                fullWidth
                style={{
                  fontFamily: "Rubik",
                  borderRadius: 20,
                  backgroundColor: lang === "es" && "#FFFFFF",
                  color: "#272F36",
                  fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                }}
                size="small"
              >
                {t("registry.lang.es")}
              </Button>
            </Box>
            <Box style={{ width: "50%" }}>
              <Button
                disabled={isLoading}
                fullWidth
                onClick={() => setLang("en")}
                style={{
                  fontFamily: "Rubik",
                  borderRadius: 20,
                  backgroundColor: lang === "en" && "#FFFFFF",
                  color: "#272F36",
                  fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                }}
                size="small"
              >
                {t("registry.lang.en")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Box style={{ marginTop: !isMobile ? 40 : 14 }}>
        <Box
          style={{
            fontFamily: "Rubik",
            fontSize: !isMobile ? 40 : height >= 1025 ? 56 : 40,
            fontWeight: 400,
            textAlign: "center",
          }}
        >
          {t("registry.create.account")}
        </Box>
        {isMobile && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: !isMobile ? 14 : 14,
              marginBottom: 8,
              width: "80%",
              borderRadius: isMobile && height >= 1025 ? 30 : 20,
              backgroundColor: "#D6D8E8",
              padding: 3,
              maxWidth: !isMobile ? 500 : height >= 1025 ? 410 : 400,
              margin: "auto",
              height: isMobile && height >= 1025 && 50,
              fontSize: isMobile && height >= 1025 && 27,
            }}
          >
            <Box style={{ width: "50%" }}>
              <Button
                disabled={isLoading}
                onClick={() => setLang("es")}
                fullWidth
                style={{
                  fontFamily: "Rubik",
                  borderRadius: isMobile && height >= 1025 ? 30 : 20,
                  backgroundColor: lang === "es" && "#FFFFFF",
                  color: "#272F36",
                  height: isMobile && height >= 1025 && 45,
                  fontSize: isMobile && height >= 1024 && 18,
                }}
                size="small"
              >
                {t("registry.lang.es")}
              </Button>
            </Box>
            <Box style={{ width: "50%" }}>
              <Button
                disabled={isLoading}
                fullWidth
                onClick={() => setLang("en")}
                style={{
                  fontFamily: "Rubik",
                  borderRadius: isMobile && height >= 1025 ? 30 : 20,
                  backgroundColor: lang === "en" && "#FFFFFF",
                  color: "#272F36",
                  height: isMobile && height >= 1025 && 45,
                  fontSize: isMobile && height >= 1024 && 18,
                }}
                size="small"
              >
                {t("registry.lang.en")}
              </Button>
            </Box>
          </Box>
        )}

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 8,
          }}
        >
          <Box
            style={{
              fontFamily: "Rubik",
              fontSize: !isMobile ? 13 : height >= 1025 ? 22 : 13,
              fontWeight: 600,
              paddingRight: 4,
            }}
          >
            {t("registry.already.account")}
          </Box>
          <a href={environment.adminURL} style={{ textDecoration: "none" }}>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 22 : 14,
                fontWeight: 600,
                color: "#EB3E67",
                fontStyle: "italic",
              }}
            >
              {t("registry.signin.here")}
            </Box>
          </a>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: height < 1025 && width > 750 && 32,
          paddingRight: height < 1025 && width > 750 && 32,
        }}
      >
        <Box
          style={{
            width: "100%",
            maxWidth: !isMobile && !isMobile ? 550 : 750,
            marginTop: !isMobile ? 25 : 19,
            marginBottom: !isMobile ? 25 : height >= 1025 ? 40 : 19,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ width: "10%" }}></Box>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
              }}
            >
              {t("registry.company.name")}
            </Box>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "10%", textAlign: "center" }}>
              <img
                src={compayIcon}
                alt=""
                style={{
                  width: height >= 1025 ? 53.42 : 28,
                  height: height >= 1025 ? 49.61 : 26,
                }}
              />
            </Box>
            <OutlinedInput
              type="text"
              id="companyName"
              disabled={isLoading}
              autoComplete="off"
              required
              value={companyName}
              onChange={(event) => {
                setCompanyName(event.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              style={{
                maxWidth: !isMobile && !isMobile ? 550 : 750,
                borderRadius: !isMobile
                  ? "4px"
                  : height >= 1025
                  ? "8px"
                  : "4px",
                background: "#E6E7F3",
                borderWidth: 0,
                fontWeight: 500,
                height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                padding: 0,
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
              }}
              placeholder={t("registry.company.name")}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            maxWidth: !isMobile ? 550 : 750,
            marginBottom: !isMobile ? 25 : height >= 1025 ? 40 : 19,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ width: "10%" }}></Box>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
                width: "45%",
              }}
            >
              {t("registry.firstname")}
            </Box>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
                width: "45%",
              }}
            >
              {t("registry.lastname")}
            </Box>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "10%", textAlign: "center" }}>
              <img
                src={userIcon}
                alt=""
                style={{
                  width: height >= 1025 ? 53.42 : 28,
                  height: height >= 1025 ? 49.61 : 26,
                }}
              />
            </Box>
            <Box style={{ width: "50%", paddingRight: 8 }}>
              <OutlinedInput
                type="text"
                id="firstName"
                disabled={isLoading}
                autoComplete="off"
                required
                value={firstName}
                onChange={(event) => {
                  if (regexForName.test(event.target.value))
                    setFirstName(event.target.value);
                }}
                inputProps={{ maxLength: 20 }}
                style={{
                  maxWidth: !isMobile ? 550 : 750,
                  borderRadois: !isMobile
                    ? "4px"
                    : height >= 1025
                    ? "8px"
                    : "4px",
                  background: "#E6E7F3",
                  borderWidth: 0,
                  fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                  fontWeight: 500,
                  height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                }}
                placeholder={t("registry.firstname")}
                fullWidth
              />
            </Box>
            <Box style={{ width: "50%" }}>
              <OutlinedInput
                type="text"
                id="lastName"
                disabled={isLoading}
                autoComplete="off"
                required
                value={lastName}
                onChange={(event) => {
                  if (regexForName.test(event.target.value))
                    setLastName(event.target.value);
                }}
                inputProps={{ maxLength: 20 }}
                style={{
                  maxWidth: !isMobile ? 550 : 750,
                  borderRadius: !isMobile
                    ? "4px"
                    : height >= 1025
                    ? "8px"
                    : "4px",
                  background: "#E6E7F3",
                  borderWidth: 0,
                  height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                  fontWeight: 500,
                  fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                }}
                placeholder={t("registry.lastname")}
                fullWidth
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            maxWidth: !isMobile ? 550 : 750,
            marginBottom: isMobile
              ? height >= 1025
                ? 40
                : isValidateEmail
                ? 14
                : 25
              : isValidateEmail
              ? 14
              : 25,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ width: "10%" }}></Box>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
              }}
            >
              {t("registry.email")}
            </Box>
          </Box>

          <Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ width: "10%", textAlign: "center" }}>
                <img
                  src={emailIcon}
                  alt=""
                  style={{
                    width: height >= 1025 ? 53.42 : 28,
                    height: height >= 1025 ? 49.61 : 26,
                  }}
                />
              </Box>
              <OutlinedInput
                id="email"
                type="email"
                disabled={isLoading}
                autoComplete="off"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                style={{
                  maxWidth: !isMobile ? 550 : 750,
                  borderRadius: !isMobile
                    ? "4px"
                    : height >= 1025
                    ? "8px"
                    : "4px",
                  background: "#E6E7F3",
                  borderWidth: 0,
                  height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                  fontWeight: 500,
                  fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                }}
                inputProps={{
                  maxLength: 100,
                  style: {
                    padding: "0px 0px 0px 12px",
                  },
                }}
                placeholder={t("ejemplo@correo.com")}
                fullWidth
              />
            </Box>
            {isValidateEmail && (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "10%", textAlign: "center" }}></Box>
                <Box style={{ color: "#D41924", fontSize: 12 }}>
                  {t("registry.format.email")}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            maxWidth: !isMobile ? 550 : 750,
            marginBottom: isMobile
              ? height >= 1025
                ? 40
                : phone.toString().length !== 10 && phone !== ""
                ? 14
                : 25
              : phone.toString().length !== 10 && phone !== ""
              ? 14
              : 25,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: !isMobile
                  ? "18%"
                  : height >= 1024 || width > height
                  ? "10%"
                  : "20%",
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
              }}
            >
              {t("registry.phone.code")}
            </Box>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
                width: "80%",
                paddingLeft: !isMobile ? "none" : height >= 1025 ? "4%" : "4%",
              }}
            >
              {t("registry.phone")}
            </Box>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box
              style={{
                width: isMobile
                  ? height >= 1024 || width > height
                    ? "15%"
                    : "30%"
                  : "20%",
                paddingRight: 2,
              }}
            >
              <FormControl
                id="formControl"
                disabled={isLoading}
                fullWidth
                style={{
                  background: "rgb(230, 231, 243)",
                  fontWeight: 500,
                  height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                  borderRadius: 4,
                }}
              >
                <Select
                  value={codePhone}
                  onChange={(event) => setCodePhone(event.target.value)}
                  style={{
                    padding: 0,
                    height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                    fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                  }}
                  inputComponen={{
                    style: {
                      fontSize: 1000,
                    },
                  }}
                >
                  {phoneCodes.map((code, index) => (
                    <MenuItem
                      id={`code-${code}-${index}`}
                      key={code.id}
                      value={code.id}
                      style={{
                        borderRadius: 4,
                        // padding: 0,
                        padding: 12,
                        minHeight: 0,
                        height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                        fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                      }}
                    >
                      {code.code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <OutlinedInput
              id="phone"
              type="phone"
              disabled={isLoading}
              inputProps={{
                maxLength:
                  codePhone === 0 || codePhone === 1 || codePhone === 3
                    ? 10
                    : codePhone === 2
                    ? 9
                    : 11,
                style: {
                  padding: "0px 0px 0px 12px",
                },
              }}
              autoComplete="off"
              required
              value={phone}
              onChange={(event) => {
                if (!regexForPhone.test(event.target.value)) {
                  return;
                } else {
                  event.target.value === ""
                    ? setPhone("")
                    : setPhone(parseInt(event.target.value));
                }
              }}
              style={{
                maxWidth: !isMobile ? 550 : 750,
                borderRadius: !isMobile
                  ? "4px"
                  : height >= 1025
                  ? "8px"
                  : "4px",
                background: "#E6E7F3",
                borderWidth: 0,
                height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                padding: 0,
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
              }}
              placeholder={t("registry.phone")}
              fullWidth
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {phone.toString().length < 9 && phone !== "" && (
              <>
                <Box
                  style={{
                    width: "20%",
                    fontFamily: "Rubik",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                ></Box>
                <Box style={{ color: "#D41924", fontSize: 12, width: "100%" }}>
                  {t("registry.not.match.phone")}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            maxWidth: !isMobile ? 550 : 750,
            marginBottom: isMobile ? (height >= 1025 ? 40 : 25) : 25,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ width: "10%" }}></Box>
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                fontWeight: 600,
              }}
            >
              {t("registry.password")}
            </Box>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "10%", textAlign: "center" }}>
              <img
                src={passwordIcon}
                alt=""
                style={{
                  width: height >= 1025 ? 53.42 : 28,
                  height: height >= 1025 ? 49.61 : 26,
                }}
              />
            </Box>
            <Tooltip
              style={{ width: "80%" }}
              disableHoverListener
              open={openTooltip}
              title={
                <div style={{ padding: 24 }}>
                  <div
                    style={{
                      color: validatePasswordForOneLetter
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.letter")}
                  </div>

                  <div
                    style={{
                      color: validatePasswordForOneLetterUppercase
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.capitalize.letter")}
                  </div>
                  <div
                    style={{
                      color: validatePasswordForOneNumber
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.minNumber")}
                  </div>
                  <div
                    style={{
                      color: validatePasswordLength ? "#0EF00F" : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.minLegth")}
                  </div>
                </div>
              }
              placement="bottom-start"
            >
              <OutlinedInput
                id="password"
                disabled={isLoading}
                type={isShowPassword ? "text" : "password"}
                autoComplete="off"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                style={{
                  maxWidth: !isMobile ? 550 : 750,
                  borderRadius: 4,
                  background: "#E6E7F3",
                  borderWidth: 0,
                  fontWeight: 500,
                  height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                  fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                }}
                inputProps={{
                  maxLength: 50,
                  style: {
                    padding: "0px 0px 0px 12px",
                  },
                }}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      disabled={isLoading}
                      color="primary"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? (
                        <VisibilityOffIcon
                          position="end"
                          fontSize={
                            !isMobile
                              ? "small"
                              : height >= 1025
                              ? "large"
                              : "small"
                          }
                        />
                      ) : (
                        <VisibilityIcon
                          position="end"
                          fontSize={
                            !isMobile
                              ? "small"
                              : height >= 1025
                              ? "large"
                              : "small"
                          }
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={t("registry.password")}
                onFocus={() => setOpenTooltip(true)}
                onBlur={() => setOpenTooltip(false)}
                fullWidth
              />
            </Tooltip>
          </Box>
        </Box>
        {password && (
          <Box
            style={{
              width: "100%",
              maxWidth: !isMobile ? 550 : 750,
              marginBottom:
                confirmPassword !== password && confirmPassword !== ""
                  ? 14
                  : 19,
            }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box style={{ width: "10%" }}></Box>
                <Box
                  style={{
                    fontFamily: "Rubik",
                    fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                    fontWeight: 600,
                  }}
                >
                  {t("registry.confirm.password")}
                </Box>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "10%", textAlign: "center" }}>
                  <img
                    src={passwordIcon}
                    alt=""
                    style={{
                      width: height >= 1025 ? 53.42 : 28,
                      height: height >= 1025 ? 49.61 : 26,
                    }}
                  />
                </Box>
                <OutlinedInput
                  id="confirmPassword"
                  disabled={isLoading}
                  type={isShowConfirmPassword ? "text" : "password"}
                  autoComplete="off"
                  required
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  style={{
                    maxWidth: !isMobile ? 550 : 750,
                    borderRadius: !isMobile
                      ? "4px"
                      : height >= 1025
                      ? "8px"
                      : "4px",
                    background: "#E6E7F3",
                    borderWidth: 0,
                    fontWeight: 500,
                    height: !isMobile ? 27 : height >= 1025 ? 51.9 : 27,
                    fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
                  }}
                  inputProps={{ maxLength: 50 }}
                  placeholder={t("registry.confirm.password")}
                  fullWidth
                  endAdornment={
                    <InputAdornment>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setIsShowConfirmPassword(!isShowConfirmPassword)
                        }
                      >
                        {isShowConfirmPassword ? (
                          <VisibilityOffIcon
                            position="end"
                            fontSize={
                              !isMobile
                                ? "small"
                                : height >= 1025
                                ? "large"
                                : "small"
                            }
                          />
                        ) : (
                          <VisibilityIcon
                            position="end"
                            fontSize={
                              !isMobile
                                ? "small"
                                : height >= 1025
                                ? "large"
                                : "small"
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
            </Box>
            {confirmPassword !== password && confirmPassword !== "" && (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "10%", textAlign: "center" }}></Box>
                <Box style={{ color: "#D41924", fontSize: 12 }}>
                  {t("registry.not.match.password")}
                </Box>
              </Box>
            )}
          </Box>
        )}

        <Box
          style={{
            marginBottom: 11,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomRadio
            id="radioButton"
            disabled={isLoading}
            checked={isChecked}
            onClick={() => {
              setIsChecked(!isChecked);
            }}
            isMobile={isMobile}
            height={height}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
            }}
          >
            <div
              style={{
                paddingRight: 4,
                fontFamily: "Rubik",
                fontWeight: "bold",
              }}
            >
              {t("registry.accept.conditions")}
            </div>
            <div
              style={{
                fontFamily: "Rubik",
                fontWeight: "bold",
                color: "#EA345F",
                fontStyle: "italic",
              }}
            >
              <Link
                target="_blank"
                to="/conditions"
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "bold",
                  color: "#EA345F",
                  fontStyle: "italic",
                  textDecoration: "none",
                }}
              >
                {t("registry.terms.conditions")}
              </Link>
            </div>
          </div>
        </Box>
      </Box>

      <Box style={{ width: "100%", maxWidth: 500, margin: "auto" }}>
        <LoadingButton
          id="submitRegistryForm"
          loadingPosition="start"
          loading={isLoading}
          onClick={() =>
            onRegistry({
              name: companyName,
              firstName,
              lastName,
              email,
              phone: phone.toString(),
              password,
              baseURL: REACT_URL,
              lang,
              bannerUrl: environment.defaultBanner,
              codePhone: phoneCodes.find((el) => el.id === codePhone)?.code,
            })
          }
          disabled={
            !companyName ||
            !firstName ||
            !lastName ||
            !email ||
            !phone ||
            !password ||
            !isChecked ||
            !validatePasswordForOneLetter ||
            !validatePasswordForOneLetterUppercase ||
            !validatePasswordForOneNumber ||
            !validatePasswordLength ||
            isValidateEmail ||
            phone.toString().length < 8 ||
            password !== confirmPassword ||
            isLoading
          }
          variant="contained"
          color="primary"
          fullWidth
          style={{
            borderRadius: !isMobile ? 32 : height >= 1025 ? 60 : 32,
            textTransform: "capitalize",
            color: "#FFFFFF",
            marginBottom: 12,
            fontWeight: 700,
            boxShadow: "none",
            fontFamily: "Rubik",
            fontSize: !isMobile ? 14 : height >= 1025 ? 27 : 14,
            height: !isMobile ? 32 : height >= 1025 ? 60 : 32,
            backgroundColor:
              !companyName ||
              !firstName ||
              !lastName ||
              !email ||
              !phone ||
              !password ||
              !isChecked ||
              !validatePasswordForOneLetter ||
              !validatePasswordForOneLetterUppercase ||
              !validatePasswordForOneNumber ||
              !validatePasswordLength ||
              isValidateEmail ||
              phone.toString().length < 8 ||
              password !== confirmPassword ||
              isLoading
                ? "#D6D8E8"
                : "",
          }}
        >
          {t("registry.terms.to.registry")}
        </LoadingButton>
        {/* <Button
          variant="outlined"
          // color="primary"
          fullWidth
          style={{
            borderRadius: 20,
            textTransform: "capitalize",
            color: "#52596B",
            borderColor: "#52596B",
            fontWeight: 700,
            height: 33,
            fontFamily: "Rubik",
            fontSize: 14,
            borderColor: "#52596B",
          }}
        >
          {t("registry.back")}
        </Button> */}
      </Box>
    </Box>
  );
}

export default RegistryForm;
